import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Catalogue from "../components/catalogue"
import { Helmet } from "react-helmet"

export const query = graphql`
  query {
    allKitchenAppliancesJson {
      edges {
        node {
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default function KitchenAppliances({ data }) {
  const kitchenAppliances = data.allKitchenAppliancesJson.edges
  return (
    <Layout>
      <Helmet>
        <title>Quartz - Kitchen Appliances</title>
      </Helmet>
      <Catalogue
        headerTitle="All Kitchen Appliances"
        items={kitchenAppliances}
      />
    </Layout>
  )
}
